import React, {useRef, useState} from "react";
import "./CompilationEditingSection.css";
import Config from "../../../../utility/Config";
import ConfirmModal from "../../../../components/ConfirmModal";
import {useCompilation} from "../../../../stores/compilation";
import {useFeedbackMessage} from "../../../../stores/FeedbackMessage";
import {VideoPlayerProvider} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";
import CompilationDragAndDrop from "./CompilationDragAndDrop";
import EditClipInterval from "../EditingControls/EditClipInterval";
import {getDisplayDuration} from "../../../../utility/Utilities";
import {FaTrashAlt} from "react-icons/fa";
import LoadingScreen from "../../../../components/LoadingScreen";

function getPreviewPlaybackUrl (clips) {
    const {duration, queries} = clips.reduce((acc, clip) => {
        const fromTs = clip.state.from_timestamp.toFixed(0);
        const toTs = clip.state.to_timestamp.toFixed(0);
        acc.queries.push(`${clip.video_asset_id}:${fromTs}:${toTs}`);
        acc.duration += (toTs - fromTs) / 1000;
        return acc;
    }, {duration: 0, queries: []});
    const backend = Config.getBackend();
    const url = `${backend}/playlist.m3u8/${queries.join("_")}/Manifest.m3u8`;
    return [url, duration];
}

export default function CompilationEditingSection ({onSave, onDiscard}) {

    const {
        editIndex,
        clips,
        isPreviewMode,
        clearCompilation,
        saveCompilation,
    } = useCompilation();

    const {showFeedback} = useFeedbackMessage();
    const saveBtnRef = useRef();

    const [confirmationModal, setConfirmationModal] = useState()
    
    if (!clips || clips.length === 0) return (
        <div className="compilation-loading-screen">
            <LoadingScreen/>
        </div>
    ) 
    
    const clip = clips[editIndex] || []
    let {editingPlaybackUrl, editingDuration} = clip
    
    if (isPreviewMode) {
        [editingPlaybackUrl, editingDuration] = getPreviewPlaybackUrl(clips);
    }

    function onClipChange (changes) {
        Object.assign(clip.state, changes); 
    }

    async function onClickSave () {
        // Prevent double-clicking by disabling the button
        if (saveBtnRef.current) saveBtnRef.current.setAttribute("disabled", "disabled");
        const {error} = await saveCompilation();
        if (saveBtnRef.current) saveBtnRef.current.removeAttribute("disabled");

        if (error) {
            showFeedback("warning", error);
            return;
        }

        onSave();
        clearCompilation();
        showFeedback("success", "Compilation saved successfully");
    }

    function onClickDiscard () {

        function onConfirm () {
            setConfirmationModal(null);
            clearCompilation();
            onDiscard();
        }

        setConfirmationModal(
            <ConfirmModal
                isOpen
                onClose={() => setConfirmationModal(null)}
                onConfirm={onConfirm}
                cancelText="Continue editing"
                confirmText="Discard">
                <div className="confirm-icon-message">
                    <div className="confirm-icon"><FaTrashAlt/></div>
                    <div className="confirm-title">Are you sure you wish to discard any changes?</div>
                </div>
            </ConfirmModal>
        );
    }

    const oriEvent = clips[editIndex];
    const oriDescription = oriEvent && oriEvent.description
    const oriDuration = oriEvent && (oriEvent.to_timestamp - oriEvent.from_timestamp);
    const oriEventDuration = getDisplayDuration(oriDuration);

    return (
        <>
            <VideoPlayerProvider url={editingPlaybackUrl} duration={editingDuration} autoPlay>
                <div className="player-list-cont">
                    <div className="player">
                        <VideoPlayer/>
                    </div>
                    <div className="clip-list">
                        <CompilationDragAndDrop/>
                    </div>
                </div>
                {!isPreviewMode && (
                    <div className="video-editing-controls">
                        <div className="ori-event-info">
                            <div>{oriEventDuration}</div>
                            <div>{oriDescription}</div>
                        </div>
                        <div className="editing-container">
                            <EditClipInterval
                                key={clip.uniqueId}
                                clip={clip}
                                onChange={onClipChange}
                                isCompilation
                            />
                        </div>
                    </div>
                )}
            </VideoPlayerProvider>

            <div className="confirm-cancel-btn-cont between">
                <button
                    ref={saveBtnRef}
                    type="button"
                    onClick={onClickSave}
                    className="green-btn">
                    Save
                </button>
                <button
                    ref={saveBtnRef}
                    type="button"
                    onClick={onClickDiscard}
                    className="red-btn">
                    Discard changes
                </button>
            </div>

            {confirmationModal && confirmationModal}
        </>
    );
}