import React from "react";
import "./css/ModalStyling.css";
import CustomModal from "./CustomModal";

function ConfirmModal ({
    children, 
    isOpen,
    onClose,
    onConfirm = null, 
    cancelText = "Cancel",
    confirmText = "Confirm",
    warningModal = null,
    disableBtn = false,
    noConfirmBtn = false,
    transparent=false,
}) {

    if (transparent) return (
        <CustomModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="transparent-modal">
            <div className="">
                {children}
            </div>
        </CustomModal>
    )

    const dangerousOperations = ["Delete", "Discard", "Log out", "Clear", "Remove", "Stop"];
    
    const confirmAction = () => {
        onConfirm();
        onClose();
    }

    const confirmBtnClassName = dangerousOperations.includes(confirmText) ? "red-btn" : "green-hover-btn";

    return (
        <CustomModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="confirm-modal">
            <div className="confirm-modal-cont">
                {children}
                <div className="confirm-cancel-btn-cont center">
                    {(!warningModal && !noConfirmBtn) && 
                        <button 
                            type="button" 
                            disabled={disableBtn} 
                            className={confirmBtnClassName} 
                            onClick={confirmAction}
                            >
                            {confirmText}
                        </button>
                    }
                    <button type="button" onClick={onClose}>{cancelText}</button>
                </div>
            </div>
        </CustomModal>
    );
}

export default ConfirmModal;
