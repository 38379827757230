import {createPlaybackUrl, useLocalStorage} from "../utility/Utilities";
import Config from "../utility/Config";
import {v4 as uuid} from "uuid";
import {createContext, useContext, useState} from "react";
import {useLoginSession} from "./loginSession";
import Backend from "../utility/Backend";
import {useUpdateSearchParams} from "../utility/Utilities";
import { useFeedbackMessage } from "../stores/FeedbackMessage";

function createPlaylistClip (playlist, clip) {

    const masterAsset = !!playlist.master_videoasset
    const assetDuration = masterAsset && playlist.duration_ms
    
    const {duration, url, playbackFromTimestamp} = createPlaybackUrl(clip, null, Config.paddingDuringEditing, 0, assetDuration);
    const {description, video_asset_id, from_timestamp, to_timestamp, tags, video_asset_name} = clip;
    
    return {
        // core
        description: description,
        video_asset_id: video_asset_id,
        from_timestamp: from_timestamp, // ms
        to_timestamp: to_timestamp, // ms
        tags: JSON.parse(JSON.stringify(tags)),

        // useful data set on load
        uniqueId: uuid(),
        video_asset_name: video_asset_name,
        playlistId: playlist.id,
        thumbnail: playlist.thumbnail_url,
        game: playlist.game,
        editingPlaybackUrl: url,
        editingDuration: duration, // Not sure if this is needed
        editingPlaybackFromTimestamp: playbackFromTimestamp,
        recording_timestamp: playlist.recording_timestamp,

        // data that can change in EditClipInterval
        state: {
            start: assetDuration ? 0 : Config.paddingDuringEditing,
            end: Math.min(duration, Config.paddingDuringEditing + (to_timestamp - from_timestamp)/1000),

            // Same as above, except these can mutate
            from_timestamp: from_timestamp,
            to_timestamp: to_timestamp,
        }
    }
}

// TODO temporary use this function to check asset 
// At some point, need to make a better or refactor the compilation feature to check all the clips with their asset. 
async function getClipAfterCheckingAsset (clip) {

    const {data: asset} = await Backend.get("/asset/" + clip.video_asset_id)
    
    const {duration, url} = createPlaybackUrl(clip, null, Config.paddingDuringEditing, 0, asset?.duration_ms);

    const updatedClip = structuredClone(clip)

    const originalDuration = (clip.to_timestamp - clip.from_timestamp) / 1000
    const endOfVideo = clip.to_timestamp / 1000

    const start = Config.paddingDuringEditing > (clip.from_timestamp/1000) ? clip.from_timestamp/1000 : Config.paddingDuringEditing
    const end = Math.min(start + originalDuration, start + endOfVideo)
    
    updatedClip.editingDuration = duration
    updatedClip.editingPlaybackUrl = url
    updatedClip.state.start = start
    updatedClip.state.end = end

    return updatedClip
}

async function checkClips (clips) {
    const checkedClips = await clips.reduce(async (acc, clip) => {
        const list = await acc; // Wait for the accumulator (resolved so far)
        const updatedClip = await getClipAfterCheckingAsset(clip); // Process the current clip
        return list.concat(updatedClip); // Add the updated clip to the list
    }, Promise.resolve([]));
    return checkedClips
}

function addPlaylistClipsToList (list, playlist) {
    for (let event of playlist.events) {
        list.push(createPlaylistClip(playlist, event));
    }
    return list;
}

const CompilationContext = createContext(null);

export function CompilationProvider ({children}) {

    const {showFeedback} = useFeedbackMessage();
    const [, updateSearchParams] = useUpdateSearchParams();
    const {token} = useLoginSession();
    const [editIndex, setEditIndex] = useState(0);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    // See createPlaylistClip for object definition
    const [clips, setClips, resetClips] = useLocalStorage("compilation_clips", []);
    // See initializeCompilation for object definition
    const [compilationInfo, setCompilationInfo, resetCompilationInfo] = useLocalStorage("compilation_info", {});
    const [autoSortChronologically, setAutoSortChronologically] = useState(false)
    
    if (autoSortChronologically) {
        clips.sort((a, b) => new Date(a.recording_timestamp) - new Date(b.recording_timestamp))
    }

    function clearCompilation () {
        resetCompilationInfo();
        resetClips();
        setEditIndex(0);
        setAutoSortChronologically(false)
    }

    // TODO make a restriction for longer clip, f.ex max 30 mins
    const newCompilation = (selectedVideos) => {

        const videosToCompilation = Array.from(selectedVideos.values());
        const newClips = videosToCompilation.reduce(addPlaylistClipsToList, []);

        const reqParams = {
            "description": "New compilation",
            "thumbnail_url": newClips[0].thumbnail,
            "is_private": true,
            "featured": false,
            "events": newClips.map(v => ({
                "tags": v.tags,
                "description": v.description,
                "video_asset_id": v.video_asset_id,
                "from_timestamp": v.from_timestamp,
                "to_timestamp": v.to_timestamp,
                
            }))
        };

        Backend.post("/playlist/", JSON.stringify(reqParams), {access_token: token})
            .then(async ({error, response}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed to create compilation, " + error);
                } else {
                    console.log("publish success");
                    const location = response.headers.get("Location");
                    const playlistId = /[^/]*$/.exec(location)[0];
                    await initializeCompilation(videosToCompilation, playlistId);
                    updateSearchParams({"editing": "playlist_" + playlistId, "edit_metadata": true});
                    showFeedback("success", "New compilation created successfully!");
                    return false;
                }
            })
    }

    async function initializeCompilation (playlist, id=undefined, newHighlights=false) {
        if (Array.isArray(playlist)) {
            // In this case, the assumption is that ID is provided separately - this is a newly created one
            const clips = playlist.reduce(addPlaylistClipsToList, []);
            const checkedClips = await checkClips(clips)
            setCompilationInfo({
                id: id,
                title: newHighlights ? playlist[0]?.description : "New compilation",
            });
            setClips(checkedClips);
        } else {
            // This is an existing compilation that we've hit EDIT on. I.e., only one playlist obj
            const clips = addPlaylistClipsToList([], playlist)
            const checkedClips = await checkClips(clips)          
            setClips(checkedClips);
            setCompilationInfo({
                id: playlist.id,
                title: playlist.description,
            });
        }
    }

    // TODO make a restriction for longer clip, f.ex max 30 mins
    async function addToCompilation (playlists) {
        const newClips = playlists.reduce(addPlaylistClipsToList, []);
        const checkedClips = await checkClips(newClips)
        setClips([...clips, ...checkedClips]);
    }

    function removeFromCompilation (idx) {
        const list = clips.slice();
        if (clips.length > 0 && idx <= editIndex) setEditIndex(Math.max(0, editIndex - 1));
        list.splice(idx, 1);
        setClips(list);
    }

    function reorderClips (srcIdx, destIdx) {
        const editItem = clips[editIndex];
        const newList = Array.from(clips);
        const [removed] = newList.splice(srcIdx, 1);
        newList.splice(destIdx, 0, removed);
        setClips(newList);
        setEditIndex(newList.indexOf(editItem));
        setAutoSortChronologically(false)
    }

    function updateClipDuration (clip) {
        const newList = Array.from(clips)
        newList[editIndex] = clip
        setClips(newList)
    }

    async function saveCompilation () {
        const reqParams = {
            "events": clips.map((clip) => {
                const fromTimestamp = clip.state?.from_timestamp || clip.from_timestamp
                const toTimestamp = clip.state?.to_timestamp || clip.to_timestamp
                return {
                    tags: clip.tags,
                    description: clip.description,
                    video_asset_id: clip.video_asset_id,
                    from_timestamp: fromTimestamp,
                    to_timestamp: toTimestamp,
                }
            }),
        };

        const path = `/playlist/${compilationInfo.id}/`;
        return await Backend.put(path, JSON.stringify(reqParams), {access_token: token});
    }

    const context = {
        playlistId: compilationInfo.id, // undefined when we're not editing - should always be checked
        compilationExists: compilationInfo.id !== undefined,
        clips,
        compilationInfo, // See initializeCompilation for object definition
        setCompilationInfo,
        editIndex,
        isPreviewMode,
        autoSortChronologically,

        setEditIndex,
        setIsPreviewMode,
        newCompilation,
        initializeCompilation,
        reorderClips,
        clearCompilation,
        saveCompilation,
        addToCompilation,
        removeFromCompilation,
        updateClipDuration,
        setAutoSortChronologically,
    }

    return (
        <CompilationContext.Provider value={context}>
            {children}
        </CompilationContext.Provider>
    );
}

export function useCompilation () {
    const context = useContext(CompilationContext);
    if (context === undefined)
        throw new Error("useCompilation used outside of its provider");
    return context;
}