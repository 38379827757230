import React, {useEffect, useState, useRef} from "react";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "../../../components/VideoPlayer/VideoPlayerProvider";
import "./MetadataSection.css";
import CreateNewSubclip from "./EditingControls/CreateNewSubclip";
import { useCompilation } from "../../../stores/compilation";
import VideoEditButtons from "./EditingControls/VideoEditButtons";
import VideoMetadata from "./VideoMetadata";
import { EditExistingClip } from "./EditingControls/EditExistingClip";
import CompilationEditingSection from "./EditCompilation/CompilationEditingSection";
import ConfirmModal from "../../../components/ConfirmModal";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useCheckPermissions } from "../../../utility/UserGroups";
import {FullMatchTimeline} from "./FullMatchTimeline";
import Config from "../../../utility/Config";
import ExportClip from "./EditingControls/ExportClip";
import UploadMessage from "../../UploadVideo/UploadMessage";

export const [
    EDIT_MODE_NONE,
    EDIT_MODE_SUBCLIP,
    EDIT_MODE_CLIP,
    EDIT_MODE_COMPILATION,
    EDIT_MODE_EXPORT
] = [0, 1, 2, 3, 4]

export default function PlaylistMetadataSection ({
    playlist,
    onMutate,
    setHideEventMetadata = null
}) {

    const videoPlayerRef = useRef(null)

    const { playlistId, clearCompilation } = useCompilation()
    const [hasEditPermission, hasGrantPermission, hasSubclipPermission] = useCheckPermissions(playlist)

    const [mode, setMode] = useState(playlistId === playlist.id ? EDIT_MODE_COMPILATION : EDIT_MODE_NONE);
    const [warningModal, setWarningModal] = useState(null);

    // TODO state to hide event metadata
    useEffect(() => {
        if (setHideEventMetadata) {
            if (mode === EDIT_MODE_SUBCLIP) setHideEventMetadata(true)
            else setHideEventMetadata(false)
        }
    }, [mode, setHideEventMetadata])

    // TODO window.scrollTo(0,0) doesn't work here. scrollIntoView is the fix in the meantime.
    const scrollToTop = () => {
        if (videoPlayerRef.current) videoPlayerRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => { scrollToTop() }, [])
    
    const isCompilation = playlistId === playlist.id || playlist.events.length > 1;
    const isHockeyMasterAsset = Config.association === "SHL" && !!playlist.master_videoasset
    // TODO maybe a better check for showing full match timeline
    const masterAsset = playlist.master_videoasset
    const liveMasterAsset = !!playlist.master_videoasset && !!playlist.is_live && !!playlist.game
    const gameMasterAsset = !!playlist.master_videoasset && !!playlist.game && !playlist.is_placeholder
    const showTimeline = Config.showFullAssetsOnMatchScreen ? (gameMasterAsset || liveMasterAsset) : false
    const exportVOD = !masterAsset && mode === EDIT_MODE_EXPORT
    // for some reason master asset with preroll always stops when starting export, so it needs autoplay
    const exportMasterWithPreRoll = masterAsset && playlist.prerolls.length !== 0 && mode === EDIT_MODE_EXPORT
    const subclipMasterWithPreRoll = masterAsset && playlist.prerolls.length !== 0 && mode === EDIT_MODE_SUBCLIP
    const enableAutoPlay = mode === EDIT_MODE_CLIP || exportVOD || exportMasterWithPreRoll || subclipMasterWithPreRoll
    const isPlaceholder = playlist.is_placeholder
    const showEditButtons = mode === EDIT_MODE_NONE && !isPlaceholder

    const clearMode = () => {
        setMode(EDIT_MODE_NONE)
        scrollToTop()
    };

    function onSave (mutation = null) {
        setMode(EDIT_MODE_NONE);
        if (mutation) onMutate(mutation);
        else onMutate();
    }

    function onDiscard () {
        setMode(EDIT_MODE_NONE)
        clearCompilation()
    }

    const checkIsEditingCompilation = () => {
        setWarningModal(
            <ConfirmModal
                isOpen
                onClose={() => setWarningModal(null)}
                cancelText="Back"
                noConfirmBtn>
                <div className="confirm-icon-message">
                    <div className="confirm-icon"><AiOutlineExclamationCircle /></div>
                    <div className="confirm-title">You are editing an existing compilation.</div>
                    <div className="confirm-title">Please save or cancel the current editing in order to create a new one.</div>
                </div>
            </ConfirmModal>
        );
    }

    const openNoPermissionModal = () => {
        setWarningModal(
            <ConfirmModal
                isOpen
                onClose={() => setWarningModal(null)}
                cancelText="Back"
                noConfirmBtn>
                <div className="confirm-icon-message">
                    <div className="confirm-icon"><AiOutlineExclamationCircle /></div>
                    <div className="confirm-title">You don't have permission to perform this action.</div>
                </div>
            </ConfirmModal>
        )
    }

    return (
        <>
            {mode === EDIT_MODE_COMPILATION && (
                <CompilationEditingSection onSave={onSave} onDiscard={onDiscard} />
            )}
            {mode !== EDIT_MODE_COMPILATION && (
                <VideoPlayerProvider
                    key={playlist.id}
                    url={playlist.video_url}
                    // TODO The PrePostroll was added quickly - probable regressions, not sure how it works with various editing things
                    withPrePostroll={mode === EDIT_MODE_NONE}
                    playlist={playlist}
                    autoPlay={enableAutoPlay}>
                    <VideoPlayer videoPlayerRef={videoPlayerRef} />
                    {(showTimeline && mode === EDIT_MODE_NONE) && <FullMatchTimeline playlist={playlist} />}
                    {mode === EDIT_MODE_CLIP && (
                        <EditExistingClip onSave={onSave} onDiscard={clearMode} />
                    )}
                    {mode === EDIT_MODE_SUBCLIP && (
                        // TODO REMOVE hockeyMasterAsset param
                        <CreateNewSubclip playlist={playlist} hockeyMasterAsset={isHockeyMasterAsset} onDone={clearMode} />
                    )}
                    {mode === EDIT_MODE_EXPORT && (
                        <ExportClip playlist={playlist} hockeyMasterAsset={isHockeyMasterAsset} onDone={clearMode}/>
                    )}
                </VideoPlayerProvider>
            )}
            {isPlaceholder && (
                <UploadMessage assetId={masterAsset.id}/>
            )}
            {showEditButtons && (
                <VideoEditButtons
                    playlist={playlist}
                    isCompilation={isCompilation}
                    setMode={setMode}
                    checkIsEditingCompilation={checkIsEditingCompilation}
                    openNoPermissionModal={openNoPermissionModal}
                    hasEditPermission={hasEditPermission}
                    hasSubclipPermission={hasSubclipPermission}
                />
            )}
            <br />
            <br />
            {mode !== EDIT_MODE_SUBCLIP && (
                <VideoMetadata playlist={playlist}
                    onMutate={onMutate}
                    hasEditPermission={hasEditPermission}
                    hasGrantPermission={hasGrantPermission}
                    openNoPermissionModal={openNoPermissionModal}
                />
            )}
            {warningModal}
        </>
    )
}